import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { UnderConstruction } from '../components/under-construction';

const About = () => (
  <Layout>
    <SEO title="About Us" />
    <UnderConstruction />
  </Layout>
);

export default About;
